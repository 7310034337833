import { render, staticRenderFns } from "./CaProductCard.vue?vue&type=template&id=04fc9ba2"
import script from "./CaProductCard.vue?vue&type=script&lang=js"
export * from "./CaProductCard.vue?vue&type=script&lang=js"
import style0 from "./CaProductCard.vue?vue&type=style&index=0&id=04fc9ba2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaImage: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaImage/CaImage.vue').default,CaClickable: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaClickable/CaClickable.vue').default,CaToggleFavorite: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaToggleFavorite/CaToggleFavorite.vue').default,CaSkeleton: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaSkeleton/CaSkeleton.vue').default,CaBrandAndName: require('/app/components/atoms/CaBrandAndName/CaBrandAndName.vue').default,CaPrice: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaPrice/CaPrice.vue').default,CaCampaigns: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaCampaigns/CaCampaigns.vue').default,CaStockDisplay: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaStockDisplay/CaStockDisplay.vue').default,CaButton: require('/app/node_modules/@geins/ralph-ui/components/atoms/CaButton/CaButton.vue').default})
