
/*
  This product is the main component for displaying a product card. 
  To understand more about this component, please read the documentation for the MixProductCard in Ralph UI
*/
import MixProductCard from 'MixProductCard';
export default {
  name: 'CaProductCard',
  mixins: [MixProductCard],
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {},
};
